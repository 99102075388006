import React from "react"
import Modal from "react-bootstrap/Modal"
import { IoMdClose } from "react-icons/io"

const InputModal = props => {

 const submit = (event) => {
    if(event.key === 'Enter') {
      document.getElementById("okayBtn").click()
    }
  }

  return (
   <>
     <Modal show={props.show}>
       <Modal.Header>
         <Modal.Title>
           <h6 className="new-pin-title">
             <IoMdClose onClick={props.onHide} style={{ cursor: "pointer" }}></IoMdClose>
             &nbsp;{props.title}
           </h6>
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <p>{props.text}</p>
         <div className = "form-group text-center">
           <input placeholder={props.placeholder} type={props.type} className = "auth-input" ref={props.inputRef} hidden={props.inputHidden} onKeyPress={submit} autocomplete="off"/>
           <button className = "auth-btn-google col-md-6 text-center" onClick={props.googleBtnAction} hidden={props.googleBtnHidden}>Continue with Google</button>
           <p id="error" style={{color: "red"}} hidden={props.errorHidden}>Incorrect Password</p>
         </div>
       </Modal.Body>
       <Modal.Footer>
         <button className="post-btn" onClick={props.onHide} hidden={props.cancelButtonHidden}>
           Cancel
         </button>
         <button id="okayBtn" className="post-btn" onClick={props.action} hidden={props.okayBtnHidden}>
           Ok
         </button>
       </Modal.Footer>
     </Modal>
   </>
  )
}

export default InputModal