import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import ProfileComponent from "../components/profile-component"


const ProfilePage = () => (

    <ProfileComponent></ProfileComponent>
    
  )
  
  export default ProfilePage
