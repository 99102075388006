/**
 * Cancel Pro Dialog
 */

import React from 'react';
import {Dialog, DialogTitle, DialogContent, Button, IconButton, Divider} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Auth from '../../Auth'
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import axios from 'axios'
// notifications
import 'react-notifications/lib/notifications.css';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection:'column'
  },
}));

const auth = new Auth();


const CancelProDialog = ({open, handleClose, subscriptionId}) => {

    const classes = useStyles();
    const [reason, setReason] = React.useState('');
    const [progrees, setProgress] = React.useState(false)

    const handleChange = (e) => {
        setReason(e.target.value);
    }

    const cancelPro = async () => {

        if(reason === ''){
            NotificationManager.error('Pleas type the cancel reason!');
        }else{
            setProgress(true)
            console.log(subscriptionId)
            axios.post('https://us-central1-cloudpin-pin-669.cloudfunctions.net/stripeWebhookHandler/subscription/cancel',{subscription_id:subscriptionId}).then( async(res)=>{
                console.log(res)
                if(res.data.success){
                    const res = await auth.cancelSubsciption(reason);
                    if(res.success){
                        NotificationManager.success('Cancelled subscription successfully!')
                        setProgress(false)
                        handleClose(true)
                    }else{
                        NotificationManager.error('Cancel Subscription failed!');
                        setProgress(false)
                    }
                }

            })

        }
        
    }

    return(
        <div>
            <NotificationContainer />
            {
                progrees && 
                <Backdrop className={classes.backdrop} open={open} style={{zIndex: 999999}}>
                    <CircularProgress color="inherit" />
                    <Typography>Cancelling Subscription...</Typography>
                </Backdrop>
            }
            <Dialog open={open}>
                <DialogTitle className='text-center p-0' style={{minWidth:350, width: '30vw'}}>
                    <IconButton
                        className='float-left'
                        onClick={()=>{handleClose (false)}}
                    >
                        <CloseIcon fontSize='large'/>
                    </IconButton>
                    <div className='b-7 py-3 mr-5'> <h5>Please let us know why you're canceling</h5> </div>
                </DialogTitle>
                <Divider/>
                <DialogContent
                    className='text-center'
                >
            
                <div>
                    <textarea 
                        className="form-control"
                        onChange= {handleChange}
                        value={reason}
                        style={{height: 200}}
                        disabled={progrees}
                    >
                    </textarea>
                </div>

                <Button
                    variant='contained'
                    className='w-100 my-3'
                    style={{backgroundColor:'rgb(232, 87, 58)', color:'white'}}
                    onClick = {() => {cancelPro()}}
                    disabled = {progrees}
                >
                    CANCEL PRO
                </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default CancelProDialog;
