import React from "react"
import Sidebar from "react-sidebar"
import DesktopSidebar from "./sidebar"
import { IoMdMenu } from "react-icons/io";
import Header from "./header"
import EditProfile from "./edit-profile"

class ProfileComponent extends React.Component {
  constructor(props) {
     super(props)
     this.state = {
       sidebarOpen: false,
     }
     this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
   }

   onSetSidebarOpen(open) {
     this.setState({ sidebarOpen: open })
   }

  render() {
   return (
         <Sidebar
           sidebar={<DesktopSidebar> </DesktopSidebar>}
           open={this.state.sidebarOpen}
           onSetOpen={this.onSetSidebarOpen}
           styles={{ sidebar: { background: "white" } }}
         >
         <div className = "sidebar-btn">
         <IoMdMenu size={24} onClick={() => this.onSetSidebarOpen(true)}/>
         </div>

           <div className="d-flex">
           <div className = "desktop-only">
             <DesktopSidebar> </DesktopSidebar>
             </div>
             <div className="" style={{ width: "100%" }}>
               <Header></Header>
               <div className="content-wrapper">
                 <div className="row">
                   <div className="col-md-12" style={{ marginBottom: "-25px" }}>
                     <h2>Edit Profile</h2>
                   </div>
                   <div style={{ marginLeft: "-10px", width:"100%", paddingTop: "25px"}}>
                    <EditProfile></EditProfile>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </Sidebar>
       )
  }
}

export default ProfileComponent