import React from "react"
import Modal from "react-bootstrap/Modal"
import { IoMdClose } from "react-icons/io"

const ConfirmationModal = props => {

  return (
   <>
     <Modal show={props.show}>
       <Modal.Header>
         <Modal.Title>
           <h6 className="new-pin-title">
             <IoMdClose onClick={props.onHide} style={{ cursor: "pointer" }}></IoMdClose>
             &nbsp;{props.title}
           </h6>
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <p>{props.text}</p>
       </Modal.Body>
       <Modal.Footer>
         <button className="post-btn" onClick={props.onHide} hidden={props.cancelButtonHidden}>
           Cancel
         </button>
         <button className="post-btn" onClick={props.action}>
           Ok
         </button>
       </Modal.Footer>
     </Modal>
   </>
  )
}

export default ConfirmationModal