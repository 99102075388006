import React from "react"
import { FaPencilAlt } from "react-icons/fa"
import firebase from "./auth/fire-base.js"
import auth from 'firebase/app'
import "firebase/auth"
import ConfirmationModal from "./confirmation-modal"
import InputModal from "./input-modal"
import { FaCamera } from "react-icons/fa"
import CountTrial from './wigets/count-trial'
import { navigate } from "gatsby"
import {withStyles} from '@material-ui/core/styles'
import { Button } from "@material-ui/core"
import {Progress} from './wigets/progress'
import CancelProDialog from './dialogs/cancel-pro-dialog'
import {Link} from 'gatsby'
import Auth from '../Auth'
import axios from "axios"
import {NotificationManager, NotificationContainer} from 'react-notifications';
import Moment from 'react-moment';

const useStyles = ()=>({
  trialDate:{
    backgroundColor:'white',
    color:'black'
  }
})

const authUser = new Auth()

class EditProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      memberSince:'',
      deleteDialogOpen: false,
      updateDialogOpen: false,
      inputDialogOpen: false,
      updateMessage: "",
      user: "",
      uid: "",
      activeName: "",
      name: "",
      username: "",
      activeDisplayPic: "",
      updatedDisplayPic: "",
      activeCategory: "",
      categories: "",
      category: [],
      activeEmail: "",
      email: "",
      password: "",
      error: "",
      mode: "",
      image: null,
      progress: 0,
      displayPicOpacity: 1,
      success: [],
      failure: [],
      okayBtnHidden: true,
      googleBtnHidden: true,
      inputHidden: true,
      errorHidden: true,
      backDropOpen: false,
      pro:false,
      cancelProDialogOpen: false,
      expireDate: null,
      paymentVerified: false,
      subscription:null,
      customer: null,
    }
    this.categorySelect = React.createRef()
    this.handleFileClick = this.handleFileClick.bind(this)
    this.fileSelector = React.createRef()
    this.profilePicHover = this.profilePicHover.bind(this)
    this.profilePicHoverLeave = this.profilePicHoverLeave.bind(this)
    this.categorySelect = React.createRef()
    this.passwordInput = React.createRef()
    this.closeCancelProDialog = this.closeCancelProDialog.bind(this);
    this.cancelPro = this.cancelPro.bind(this);
  }

  async componentDidMount() {
    this.populateCategory()
    this.populateData()
    const user = await authUser.user();
    if(user){
        this.setState({
            memberSince: user.memberSince.seconds * 1000,
            pro: user.pro,
            expireDate: user.expireDate,
            paymentVerified: user.paymentVerified,
            subscription: user.subscription,
            customerId: user.customerId,
            customer: user,
          });
    }else{
      navigate("/sigiin")
    }
  }

  onSetDeleteDialogOpen(open) {
    this.setState({ deleteDialogOpen: open })
  }

  onSetUpdateDialogOpen(open) {
    this.setState({ updateDialogOpen: open })
  }

  onSetInputDialogOpen(open) {
    this.setState({ errorHidden: true })
    this.setState({ inputDialogOpen: open })
  }

  populateData() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        var providerId = user.providerData[0].providerId
        if (providerId === "password") {
          document.getElementById("password-div").hidden = false
          document.getElementById("email-div").hidden = false
          this.setState({ okayBtnHidden: false, inputHidden: false })
        }
        else if (providerId === "google.com") {
          this.setState({ googleBtnHidden: false })
        }

        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .onSnapshot(snapshotUser => {
            let category;
            if(snapshotUser.data()){
              category = snapshotUser.data().category
              ? snapshotUser.data().category
              : ""
            }else{
              category =""
            }
            
            document.getElementById(
              "username"
            ).value =  snapshotUser.data()
              .username
              ? snapshotUser.data().username
              : ""
            document.getElementById("email").value = snapshotUser.data().email
            document.getElementById("category").value = snapshotUser.data()
              .category
              ? snapshotUser.data().category
              : ""
            document.getElementById("name").value = snapshotUser.data()
              .name
              ? snapshotUser.data().name
              : ""
            this.setPic(snapshotUser.data().displayPic)
            this.setState({
              activeName: snapshotUser.data().name,
              activeCategory: category,
              username: snapshotUser.data().username,
              activeEmail: snapshotUser.data().email,
              activeDisplayPic: snapshotUser.data().displayPic,
              uid: user.uid,
              user: user,
            })
          })
      }
    })
  }

  setPic(pic) {
    if (pic !== undefined) {
      if (pic !== null && pic !== "") {
        this.setState({ picurl: pic })
      }
    }
  }

  setSelectedCategory(seachtext) {
    for (
      var i = 0;
      i < document.getElementById("categories").options.length;
      i++
    ) {
      if (document.getElementById("categories").options[i].text === seachtext) {
        document.getElementById("categories").selectedIndex = i
      }
    }
  }

  populateCategory() {
    firebase
      .firestore()
      .collection("dropdowns")
      .doc("category")
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({ category: doc.data().categories })
        }
      })
  }

  enableEmail() {
    document.getElementById("email").readOnly = false
  }

  enableCategories() {
    document.getElementById("categories").hidden = false
    document.getElementById("category").hidden = true
    this.setSelectedCategory(document.getElementById("category").value)
  }

  enableChangePassword() {
    document.getElementById("password").readOnly = false
  }

  enableName() {
    document.getElementById("name").readOnly = false
  }

  handleEditProfileChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  async changeEmail_Password() {
    const {
      activeEmail,
      email,
      user,
      password,
      success,
      failure,
      uid
    } = this.state

    if (activeEmail !== email && email.trim() !== "") {
      await user
        .updateEmail(email)
        .then(updated => {
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .set(
              {
                email: email,
              },
              { merge: true }
            )
            .then(() => {
              success.push("Updated: Email")
            })
            .catch(error => {
              failure.push("Failure: " + error.code)
            })
        })
        .catch(error => {
          failure.push("Failure: " + error.code)
        })
    }
    if (password.trim() !== "") {
      await user
        .updatePassword(password)
        .then(updated => {
          success.push("Updated: Password")
          document.getElementById("password").value=""
          document.getElementById("password").readOnly = true
          this.setState({ password: "" })
        })
        .catch(error => {
          failure.push("Failure: " + error.code)
        })
    }

    var message = success.join(" | ") + " | " + failure.join(" | ")

    if (message.trim() === "|") {
      message = "No Updated Performed"
    }

    this.setState({ updateMessage: message })
    this.onSetUpdateDialogOpen(true)
    this.setState({ success: [], failure: [] })
  }

  async editProfile() {
    const {
      activeName,
      name,
      categories,
      activeCategory,
      uid,
      activeEmail,
      email,
      password
    } = this.state
    var success = []
    var failure = []
    if (activeName !== name && name.trim() !== "") {
      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .set(
          {
            name: name,
          },
          { merge: true }
        )
        .then(() => {
          success.push("Updated: name")
        })
        .catch(error => {
          failure.push("Failure: " + error.code)
        })
    }
    if (activeCategory !== categories && categories.trim() !== "") {
      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .set(
          {
            category: categories,
          },
          { merge: true }
        )
        .then(() => {
          success.push("Updated: Category")
        })
        .catch(error => {
          failure.push("Failure: " + error.code)
        })
    }

    if((activeEmail !== email && email.trim() !== "") || (password.trim() !== "")) {
       this.setState({ success: success, failure: failure })
       this.onSetInputDialogOpen(true)
    }
    else {

      var message = success.join(" | ") + " | " + failure.join(" | ")

      if (message.trim() === "|") {
        message = "No Updated Performed"
      }

      this.setState({ updateMessage: message })
      this.onSetUpdateDialogOpen(true)
    }
  }

  handleFileClick = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0]
      this.setState({ image: image, picurl: "" }, () => {
        const { image } = this.state
        const uploadTask = firebase
          .storage()
          .ref(`displaypics/${image.name}`)
          .put(image)
        uploadTask.on(
          "state_changed",
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            this.setState({ progress })
            console.log(progress)
          },
          error => {
            // Error function ...
            console.log(error)
          },
          () => {
            // complete function ...
            firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .get().then((userInfo)=>{
                  if(userInfo) {
                    var userDisplayPic = userInfo.data().displayPic ? userInfo.data().displayPic : ""
                    if(userDisplayPic !== "") {
                      const store = firebase.storage().refFromURL(userDisplayPic);
                      store.delete();
                    }
                  }
                })
            firebase
              .storage()
              .ref("displaypics")
              .child(image.name)
              .getDownloadURL()
              .then(picurl => {
                this.setState({ picurl }, () => {
                  firebase
                    .firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.uid)
                    .update({ displayPic: picurl })
                    .then(() => {
                      this.setState({ activeDisplayPic: picurl })
                      document.getElementById("profile-pic").src = picurl
                      document.getElementById("pic-div").style.opacity = 1
                    })
                })
              })
          }
        )
      })
    }
  }

  profilePicHover() {
    this.setState({ displayPicOpacity: 0.5 })
  }

  profilePicHoverLeave() {
    this.setState({ displayPicOpacity: 1 })
  }

  toggle(action) {
    if (action === "delete") {
      const self = this;
      if(this.state.subscription){
         axios.post('https://us-central1-cloudpin-pin-669.cloudfunctions.net/stripeWebhookHandler/subscription/cancel',{subscription_id:this.state.subscription.id}).then( async(res)=>{
            if(res.data.success){
              self.onSetDeleteDialogOpen(false)
              self.setState({ mode: "delete" })
              self.onSetInputDialogOpen(true)
            }
        })
      }else{
        self.onSetDeleteDialogOpen(false)
        self.setState({ mode: "delete" })
        self.onSetInputDialogOpen(true)
      }
     
    } else if (action === "edit") {
      this.setState({ mode: "edit" })
      this.editProfile()
    }
  }

  async processLogin() {
    const { user } = this.state

    var password = ""
    if (this.passwordInput.current !== null)
      password = this.passwordInput.current.value

    await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, password)
      .then(user => {
        this.setState({ errorHidden: true })
        this.onSetInputDialogOpen(false)
        if (this.state.mode === "edit") {
          this.changeEmail_Password()
        } else if (this.state.mode === "delete") {
          this.deleteAccount()
          firebase.analytics().logEvent('Email & Password Login to Delete', { name: 'Delete'})
        }
        this.setState({ mode: "" })
      })
      .catch(error => {
        this.setState({ errorHidden: false})
        setTimeout(()=>{ this.setState({ errorHidden: true }) }, 3000)
      })
  }

  async deleteAccount() {
    const { user, uid, username } = this.state
    await firebase
      .firestore()
      .collection("pins")
      .where(
        "creator",
        "==",
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
      )
      .get()
      .then(pins => {
        pins.forEach(doc => {
          if (doc.exists) {
            var file = doc.data().content.file ? doc.data().content.file : ""
            if(file !== "") {
              const store = firebase.storage().refFromURL(file);
              store.delete();
            }
            this.deletePin(doc.id)
          }
        })

        firebase
          .firestore()
          .collection("bookmarks")
          .doc(uid)
          .delete()

        this.deleteStreams()

        firebase
          .firestore()
          .collection("username")
          .doc(username)
          .delete()

        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get().then((userInfo)=>{
            if(userInfo) {
              var userDisplayPic = userInfo.data().displayPic ? userInfo.data().displayPic : ""
              if(userDisplayPic !== "") {
               const store = firebase.storage().refFromURL(userDisplayPic);
               store.delete();
              }
              firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .delete()
            }
          })

        user
          .delete()
          .then(() => {
            window.location.href = "/"
          })
          .catch(function(error) {
            // An error happened.
          })
      })
    this.onSetDeleteDialogOpen(false)
  }

  async deleteStreams() {
    const { uid } = this.state
    await firebase
      .firestore()
      .collection("streams")
      .where(
        "creator",
        "==",
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
      )
      .get()
      .then(streams => {
        streams.forEach(doc => {
          if (doc.exists) {
            firebase
              .firestore()
              .collection("streams")
              .doc(doc.id)
              .delete()
          }
        })
      })
  }

  getTrialdays = (date) => {
      if(date === undefined || date === null) {
        return null;
      }else{
        const today = (new Date()).getTime();
        if((date*1000 - today)<0){
          return null;
        }else{
          return Math.ceil((date*1000 - today)/(1000 * 3600 * 24));
        }
      }
  }

  deletePin(pincode) {
    firebase
      .firestore()
      .collection("pins")
      .doc(pincode)
      .delete()
      .then(() => {
        firebase
          .firestore()
          .collection("comment")
          .doc(pincode)
          .delete()
      })
  }

  deleteGoogleAccount() {

    // Using a popup.
    var provider = new auth.auth.GoogleAuthProvider()
    provider.addScope('profile');
    provider.addScope('email');
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.deleteAccount()
      firebase.analytics().logEvent('Google Login to Delete', { name: 'Google'})
    })
    .catch((error) => {});

  }

  upgradeCheckOut = () =>{
    navigate('/upgrade')
  }

  closeCancelProDialog = async (canceled) => {
    if(canceled){
       const auth = new Auth();
       const user = await auth.user();
       this.setState({user: user})
    }
    this.setState({cancelProDialogOpen: false})
  }

  cancelPro = () => {
    this.setState({cancelProDialogOpen: true});
    let self = this
    setTimeout(function(){
        self.setState({cancelProDialogOpen: false});
        alert('Something went wrong, please check your account.')
    }, 60000)
  }

  updateCard = () =>{
    if(this.state.customerId){
      this.setState({backDropOpen: true})
      console.log(this.state.customerId)
      axios.post('https://us-central1-cloudpin-pin-669.cloudfunctions.net/stripeWebhookHandler/create_billing_portal', {customer: this.state.customerId}).then(res =>{
        if(res.data.success){
          const url = res.data.session.url;
          window.location.href=url;
        }
      })
    }else{
      alert('Somthing went wrong. Please check your payment method.')
    }
  }

  render() {
    const {classes}  = this.props;
    return (
      <div>

     
        {
          this.state.subscription && 
          <CancelProDialog 
            open={this.state.cancelProDialogOpen} 
            handleClose={ this.closeCancelProDialog } 
            subscriptionId = { this.state.subscription.id }
          />
        }
        
        <Progress open={this.state.backDropOpen} />
        <NotificationContainer />
        <div className="profile-border">
          <div className="form-group text-center" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "25px",
                alignItems:"center"
              }}
            >
              <div
                id="pic-div"
                className="auth-input"
                style={
                  this.state.picurl ? {
                   height: "105px",
                   width: "110px",
                   marginBottom: "50px",
                   marginTop: "35px",
                   cursor: "pointer",
                  } : {border: "2px dotted #ff755e",
                  borderRadius: "50%",
                  height: "105px",
                  width: "110px",
                  marginBottom: "50px",
                  marginTop: "35px",
                  cursor: "pointer",
                  opacity: this.state.displayPicOpacity,
                   }}
                onClick={
                  this.fileSelector && this.fileSelector.current
                    ? () => this.fileSelector.current.click()
                    : void 0
                }
                onMouseEnter={this.profilePicHover}
                onMouseLeave={this.profilePicHoverLeave}
              >
                <input
                  id="file-selector"
                  type="file"
                  onChange={this.handleFileClick}
                  ref={this.fileSelector}
                  hidden
                />
                {this.state.picurl ? (
                  <img
                    src={this.state.picurl}
                    id="profile-pic"
                    style={{
                      borderRadius: "50%",
                    }}
                  ></img>
                ) : (
                  <FaCamera
                    style={{
                      color: "#ff755e",
                      width: "40px",
                      height: "40px",
                      marginTop: "25px",
                      cursor: "pointer",
                    }}
                  ></FaCamera>
                )}
              </div>
              <input
                name="name"
                id="name"
                className="auth-input"
                placeholder="Name"
                style={{
                  borderBottomStyle: "hidden",
                  width: "auto",
                  height: "35px",
                  marginLeft: "25px",
                  
                }}
                onChange={this.handleEditProfileChange}
                readOnly
              />
              <FaPencilAlt
                style={{
                  display: "block",
                 
                  cursor: "pointer",
                 
                 
                }}
                onClick={() => this.enableName()}
              ></FaPencilAlt>
              <div style={{display:'flex',width:'100%', justifyContent:'flex-end'}}>
                  {
                    !this.state.pro && 
                    <Link to="/upgrade" style={{textDecoration: 'none'}}>
                      <Button
                        style={{
                          backgroundColor:'rgb(33, 223, 131)',
                          color:'white',
                          padding:'7px 60px',
                          fontSize: 20,
                          marginRight: 30,
                        }}
                      >
                        Go Pro
                      </Button>
                    </Link>   
                  }
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "25px",
                alignItems:"center",
                padding:"10px 0px",
                borderBottom: "1px solid #d3d3d3"
              }}
            >
              <label htmlFor="username" style={{ paddingLeft:"25px",marginBottom: "0px", marginRight: "40px" }}>
                USERNAME:
              </label>
              <input
                name="username"
                id="username"
                className="auth-input"
                style={{ borderBottomStyle: "hidden", width: "50%" }}
                disabled
              />
            </div>
         
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "25px",
                alignItems:"center",
                padding:"10px 0px",
                borderBottom: "1px solid #d3d3d3"
              }}
            >
              <label htmlFor="categories" style={{ paddingLeft:"25px", marginBottom: "0px", marginRight: "40px" }}>
                CATEGORY:
              </label>
              <select
                ref={this.categorySelect}
                id="categories"
                name="categories"
                className="auth-input"
                style={{
                  borderBottomStyle: "hidden",
                  marginLeft: "auto",
                  background: "white",
                }}
                onChange={this.handleEditProfileChange}
                hidden
              >
                {this.state.category.map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
                ;
              </select>
              <input
                name="category"
                id="category"
                className="auth-input"
                style={{ borderBottomStyle: "hidden", width: "50%" }}
                disabled
              />
              <FaPencilAlt
                onClick={() => this.enableCategories()}
                style={{
                  display: "inline",
                  cursor: "pointer",
                  marginLeft:"auto",
                  marginRight:"50px"
                }}
              ></FaPencilAlt>
            </div>           
            <div id="email-div" hidden>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "25px",
                alignItems:"center",
                padding:"10px 0px",
                borderBottom: "1px solid #d3d3d3"
                }}
              >
                <label htmlFor="email" style={{ paddingLeft:"25px", marginBottom:"0px", marginRight: "86px" }}>
                  EMAIL:
                </label>
                <input
                  name="email"
                  id="email"
                  className="auth-input"
                  style={{ borderBottomStyle: "hidden", width: "auto" }}
                  onChange={this.handleEditProfileChange}
                  readOnly
                />
                <FaPencilAlt
                  onClick={() => this.enableEmail()}
                  style={{
                    display: "inline",
                    cursor: "pointer",
                    marginLeft:"auto",
                    marginRight:"50px"
                  }}
                ></FaPencilAlt>
              </div>
             
            </div>
            <div id="password-div">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding:"10px 0px",
                  paddingLeft: "25px",
                alignItems:"center",
                borderBottom: "1px solid #d3d3d3"
                }}
              >
                <div className="row" style={{width: '100%'}}>

                  <div className="col-md-6 col-12">
                    <label htmlFor="email" style={{marginBottom:"0px", float:'left'}}>
                      PASSWORD:
                    </label>
                    <input
                      name="password"
                      id="password"
                      type="password"
                      className="auth-input"
                      style={{
                        borderBottomStyle: "hidden",
                        width: "150px",
                        height: "30px",
                      }}
                      onChange={this.handleEditProfileChange}
                      readOnly
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <p
                        onClick={() => this.enableChangePassword()}
                        className="auth-link"
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          marginLeft: "20px",
                          marginBottom:"0px",
                          textAlign: 'right',
                          paddingRight: 10,
                        }}
                      >
                        Change Password
                      </p>
                </div>
               </div>
              </div>             
            </div>

            
            <div id="password-div">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems:"center",
                  borderBottom: "1px solid #d3d3d3",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                 <label htmlFor="email" style={{ paddingLeft:"25px", marginBottom:"0px", float:'left'}}>
                   PLANDETAILS: 
                   </label>
                {
                  this.state.customer && this.state.customer.pro && !this.state.customer.paymentVerified &&
                  <span style={{ paddingLeft:"25px", marginBottom:"0px"}}>PRO</span>
                }
                {
                  this.state.customer && (!this.state.customer.paymentVerified || this.state.customer.paymeneError) &&
                  <CountTrial customer = {this.state.customer} ClassName = {classes.trialDate} />
                }
                {
                  this.state.customer && this.state.customer.pro && this.state.customer.paymentVerified &&
                  <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center', marginLeft: 10,}}>
                      <div>PRO</div>
                      <div>
                        <div
                          style={{
                            color:'rgb(232, 87, 58)',
                            textTransform:'capitalize',
                            fontWeight:'bold',
                            marginRight: 40,
                            cursor:'pointer'
                          }}

                          onClick = {()=>{this.cancelPro()}}
                        >
                        Cancel Pro
                        </div>
                      </div>
                  </div>
                }
                
              </div>             
            </div>
            
            <div style={{ paddingBottom: "50px", paddingTop: "10px" }}>
                {
                  this.state.customer && this.state.customer.subscription?
                  (
                    this.state.customer.paymentVerified?
                    <div style = {{ float: "left", paddingLeft: 25, }} >
                      Your plan renews on <Moment format="D MMM, YYYY">{this.state.customer.subscription.current_period_end * 1000}</Moment>
                    </div>:
                    <div style = {{ float: "left", paddingLeft: 25, }} >
                      You can access pro until <Moment format="D MMM, YYYY">{this.state.customer.subscription.current_period_end * 1000}</Moment>
                    </div>
                  ):
                  (this.state.expireDate && this.state.pro && ((this.state.expireDate.seconds * 1000) > (new Date()).getTime()) &&
                  <div
                    style = {{ float: "left", paddingLeft: 25, }}
                  >
                    {`${this.getTrialdays(this.state.expireDate.seconds)} trial days remaining`}
                  </div>)
                }
            </div>

            <div style={{ paddingBottom: "50px", paddingTop: "10px" }}>
              {
                this.state.paymentVerified &&
                    <button
                      className="save-btn"
                      style={{ float: "left" }}
                      type="submit"
                      onClick={()=>{this.updateCard()}}
                    >
                      Update Card
                    </button>
              }
              <button
                className="save-btn"
                style={{ float: "right" }}
                onClick={() => this.toggle("edit")}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="form-group text-center" style={{ width: "100%" }}>
          <br />
          <p
            className="auth-link"
            style={{ fontSize: "16px", cursor: "pointer" }}
            onClick={() => this.onSetDeleteDialogOpen(true)}
          >
            DELETE ACCOUNT
          </p>
          <ConfirmationModal
            show={this.state.deleteDialogOpen}
            onHide={() => this.onSetDeleteDialogOpen(false)}
            title="Delete Account"
            text={
              "Are you sure you want to delete your account? This operation cannot be undone."
            }
            action={() => this.toggle("delete")}
          ></ConfirmationModal>
        </div>
        <ConfirmationModal
          show={this.state.updateDialogOpen}
          onHide={() => this.onSetUpdateDialogOpen(false)}
          title="Update Account"
          action={() => this.onSetUpdateDialogOpen(false)}
          text={this.state.updateMessage}
          cancelButtonHidden={true}
        ></ConfirmationModal>
        <InputModal
          show={this.state.inputDialogOpen}
          onHide={() => this.onSetInputDialogOpen(false)}
          title={this.state.mode == "edit" ? "Password Required to Change Email/Password" : "Password Required to Delete Account"}
          type="password"
          placeholder="Password"
          action={() => this.processLogin()}
          inputRef={this.passwordInput}
          googleBtnAction={()=> this.deleteGoogleAccount()}
          googleBtnHidden={this.state.googleBtnHidden}
          okayBtnHidden={this.state.okayBtnHidden}
          inputHidden={this.state.inputHidden}
          errorHidden={this.state.errorHidden}
        ></InputModal>
      </div>
    )
  }
}

export default withStyles(useStyles)(EditProfile)
